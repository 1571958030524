import { render, staticRenderFns } from "./ErrorWithdraw.vue?vue&type=template&id=a330334e&scoped=true"
import script from "./ErrorWithdraw.vue?vue&type=script&lang=js"
export * from "./ErrorWithdraw.vue?vue&type=script&lang=js"
import style0 from "./ErrorWithdraw.vue?vue&type=style&index=0&id=a330334e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a330334e",
  null
  
)

export default component.exports